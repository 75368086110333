<template>
  <div>
    <abstraktion-navigation :modules="modules" :module="module" v-if="$route.name !== 'Packshot'" />
    <head-bar v-if="$route.name !== 'Packshot'" />
    <!-- force reload on same component -->
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import HeadBar from "./components/HeadBar.vue";
import AbstraktionNavigation from "./components/AbstraktionNavigation.vue";

export default {
  name: "App",
  components: {
    HeadBar,
    AbstraktionNavigation,
  },
  beforeMount() {
    if (
      typeof document.getElementById("app").dataset !== "undefined" &&
      typeof document.getElementById("app").dataset.modules !== "undefined"
    )
      this.modules = document.getElementById("app").dataset.modules.split(",");
  },
  data() {
    return {
      modules: [],
      module: "visual",
    };
  },
};
</script>

<style lang="scss">
@import "./scss/main.scss";
</style>
