<template>
  <div class="model page">
    <!-- 

      logged user

    -->
    <section class="section" v-if="isUserAuth">
      <h1 class="title">home</h1>

      <h2>models</h2>
      <p><router-link to="/model">See all models</router-link>.</p>

      <span v-if="model_path_id_latest">
        <h2>my latest project</h2>
        <p>
          <router-link :to="model_path_id_latest">Recent model used</router-link
          >.
        </p>
      </span>

      <span v-if="option_drive">
        <h2>REAL TIME DESIGN</h2>
        <p>
          Connect yourself to your Google Drive
          <router-link to="/drive">here</router-link> and use your designs.
        </p>
      </span>
    </section>
    <!-- 

      no user (public)

    -->
    <section v-else class="section">
      <h1 class="title">Visualize your design on a product</h1>

      <h2>Textile designer</h2>
      <p>
        Display your designs on products and produce pictures. Placement,
        colorways, communication, design validation, ideas, new markets. Try our <a href="#app">free tool</a> on the Apple store to create a palette from a picture.
      </p>
      <p><br /></p>


      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/581634876?autoplay=1&muted=1&loop=1&title=0&byline=0&portrait=0"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <sound-button id="app" />
      
      <h2>Free color palette app for Mac</h2>
      <p>Create a palette from an image, modify the colors and export as a .aco file.</p>
      <a href="https://apps.apple.com/app/abstraktion/id1606949068" target="_blank">
        <img
            class="headbar__logo"
            :src="require('../assets/app/mas.f6c6e6c.b36bcf724bc71f295292ea1fbf066aa1.svg')"
          />
        
        </a>
      
      <!-- <h2>graphic designer</h2>
      <p>See your design on an object or dress.</p>

      <br />

      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          src="https://player.vimeo.com/video/562493913?autoplay=0&muted=1&loop=1&title=0&byline=0&portrait=0"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
    </section>

    <abstraktion-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AbstraktionFooter from "../components/AbstraktionFooter.vue";
import SoundButton from '../components/SoundButton.vue';

export default {
  name: "Home",
  components: { AbstraktionFooter, SoundButton },
  data() {
    return {
      model_path_id_latest: null,
      option_drive: false,
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  mounted() {
    // open policy
    // if (true) {
    //   this.policy_open = true
    // }

    if (typeof process.env.VUE_APP_OPTION_DRIVE !== "undefined") {
      this.option_drive =
        process.env.VUE_APP_OPTION_DRIVE == "true" ? true : false;
    }

    try {
      this.model_path_id_latest = localStorage.getItem("model_path_id_latest");
    } catch {
      console.log("localStorage error");
    }
  },
};
</script>


<style lang="scss" scoped>
@import "../scss/main.scss";

.section {
  min-height: calc(100vh - 187px);
}
</style>