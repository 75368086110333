/**

router

this.$store.dispatch("logout", {});
this.$store.getters.isClient


*/
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "../firestore";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: !true,
    },
  },
  {
    path: "/tos",
    name: "Tos",
    component: () =>
      import(/* webpackChunkName: "onboard" */ "../views/Tos.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    component: () =>
      import(/* webpackChunkName: "onboard" */ "../views/Policy.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "onboard" */ "../views/Login.vue"),
  },
  {
    path: "/onboard",
    name: "Onboard",
    component: () =>
      import(/* webpackChunkName: "onboard" */ "../views/Onboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/drive",
    name: "Drive",
    component: () =>
      import(/* webpackChunkName: "drive" */ "../views/Drive.vue"),
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/model",
    name: "ModelList",
    component: () =>
      import(/* webpackChunkName: "model" */ "../views/ModelList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/model/:model_id",
    name: "Model",
    component: () =>
      import(/* webpackChunkName: "display" */ "../views/Model.vue"),
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/packshot/:model_id",
    name: "Packshot",
    component: () =>
      import(/* webpackChunkName: "display" */ "../views/Packshot.vue"),
    meta: {
      requiresAuth: !true,
      requiresClient: !true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guide",
    name: "Guide",
    component: () =>
      import(/* webpackChunkName: "guide" */ "../views/Guide.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeRouteUpdate(async (to, from, next) => {
//   next()
// })

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresClient = to.matched.some((x) => x.meta.requiresClient);
  if (await (requiresAuth && !auth.currentUser)) {
    next("/login");
  } else if (requiresClient && !store.getters.isClient) {
    next("/profile");
  } else {
    next();
  }
});

export default router;
