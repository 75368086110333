<template>
  <div class="ak-footer columns">
    <div class="column">
      <router-link to="/policy">policy</router-link> | 
      <router-link to="/tos">terms of service</router-link>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
export default {
  name: "AbstraktionFooter",
};
</script>

<style lang="scss" scoped>
.ak-footer {
  text-transform: uppercase;
}
</style>