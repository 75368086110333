import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDCnBISQ95Xex66qpUU1_ohiszTd0yGSXE",
  authDomain: "abstraktion-saas.firebaseapp.com",
  databaseURL: "https://abstraktion-saas.firebaseio.com",
  projectId: "abstraktion-saas",
  storageBucket: "abstraktion-saas.appspot.com",
  messagingSenderId: "860198814735",
  appId: "1:860198814735:web:3ad5431d9e90aad8bc4f7a",
  measurementId: "G-RER3NV9MFN",
  // apiKey: process.env.VUE_APP_API_KEY,
  // authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  // databaseURL: process.env.VUE_APP_DATABASE_URL,
  // projectId: process.env.VUE_APP_PROJECT_ID,
  // storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  // appId: process.env.VUE_APP_APP_ID,
  // measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
/*const usersCollection = db.collection('users')
 */

// export utils/refs
export {
  db,
  auth,
  //usersCollection,
};
