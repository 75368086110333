<template>
  <div class="component-sound-button">
    <div
      :class="{ video__sound: true, active: !muted }"
      id="videosound"
      @click="volume()"
    >
      <span class="video__sound-text"
        >sound <span v-if="!muted">on</span><span v-else>off</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      muted: true,
    };
  },
  methods: {
    volume() {console.log('*')
      //if (process.isClient) {
        let iframe = document.getElementsByTagName("iframe")[0];
        if (this.muted)
          iframe.contentWindow.postMessage(
            '{"method":"setVolume", "value":0.7}',
            "*"
          );
        else
          iframe.contentWindow.postMessage(
            '{"method":"setVolume", "value":0}',
            "*"
          );
        this.muted = !this.muted;
      //}
    },
  },
};
</script>

<style scoped lang="scss">
.component-sound-button {
  margin: 1em;
  .video__sound {
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em;
    transition: all 1s;
    cursor: pointer;
    &.active {
      opacity: 0.2;
    }
    &:hover {
      opacity: 0.5;
    }

    &:not(.active) {
      animation: blinker 4s linear infinite;
    }
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    .video__sound-text {
      border: 1px solid;
      padding: 0.25em 0.75em;
      min-width: 6em;
      //display: flex;
      &:nth-child(1) {
        //animation: blinker 4s infinite;
      }
    }
  }
}
</style>