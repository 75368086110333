<template>
  <div class="component headbar">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            class="headbar__logo"
            :src="require('../assets/abstraktion/abstraktion-fashion-logo.svg')"
          />
        </b-navbar-item>
      </template>
      <template #start v-if="isUserAuth">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          Home
        </b-navbar-item>
        <!--b-navbar-item tag="router-link" :to="{ path: '/login' }">
                Login
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/onboard' }">
                Onboard
            </b-navbar-item-->
        <b-navbar-item
          v-if="isDriveOption()"
          tag="router-link"
          :to="{ path: '/drive' }"
        >
          Drive
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/model' }">
          Model
        </b-navbar-item>
        <b-navbar-item
          v-if="isStudioVisible()"
          tag="router-link"
          :to="{ path: '/model/studio' }"
        >
          Studio
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/profile' }">
          Profile
        </b-navbar-item>
        <!-- <b-navbar-item tag="router-link" :to="{ path: '/guide' }">
          How To
        </b-navbar-item> -->

        <!--b-navbar-dropdown label="demo">
                <b-navbar-item href="#">
                    demo
                </b-navbar-item>
                <b-navbar-item href="#">
                    demo
                </b-navbar-item>
            </b-navbar-dropdown-->
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons" v-if="$route.name !== 'Login'">
            <router-link
              to="/login"
              v-if="!isUserAuth"
              class="button is-primary link"
            >
              <strong>Sign up</strong>
            </router-link>
            <router-link
              to="/login"
              v-if="!isUserAuth"
              class="button is-light link"
            >
              Log in
            </router-link>
            <a v-if="isUserAuth" @click="logout()" class="is-light link mx-4">
              Log out
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
//import store from "./../store";
import { mapGetters } from "vuex";

export default {
  name: "HeadBar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth", "getModules"]),
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("logout", {});
    },
    isDriveOption() {
      if (process.env.VUE_APP_OPTION_DRIVE == "true") return true;
      return false;
    },
    isStudioVisible() {
      if (
        localStorage.getItem("model_picture") &&
        localStorage.getItem("model_mask") &&
        localStorage.getItem("model_grid")
      )
        return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.headbar {
  text-transform: uppercase;

  .navbar {
    background-color: rgba(255, 255, 255, 0);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }
    .navbar-brand .navbar-item {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
  }

  &__logo {
    height: 100px; //5rem;
    max-height: unset;
  }
}
</style>